import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tmsConfig } from '../../../../core/tms-config';
// import { ModalService } from 'sce-ui-lib';
import {
  AlertService,
  ModalService,
  ResourceService,
  UserService,
} from 'sce-core';
import { Paginator } from 'primeng-lts/paginator';
import { ParamsService } from '../../params.service';
import { DynamicDialogRef } from 'primeng-lts/dynamicdialog';
// import { Paginator } from 'primeng/primeng';
import { catchError, map } from 'rxjs/operators';
import { generateGid } from '../../../../shared/services/business-unit-gid.util';

@Component({
  selector: 'lfwms-dashboard-truck-type-detail',
  templateUrl: './truck-type-detail.component.html',
  styleUrls: [
    '../../shipment/shipment-home/dispatched-order/dispatched-order.component.css',
    './common-overwrite.component.css',
  ],
})
export class TruckTypeDetailComponent implements OnInit {
  public searchService: any;
  public cancelHandler: any;
  public confirmHandler: any;
  public confirmHandler2: any;
  public confirmHandler3: Function;
  public columnDef: any[] = [];
  public listPerPage: any[] = [];
  public servprovGid: string;
  public selectedRow: any;
  pageInfo: any = { page: 0, size: 5, total: 0, content: [] };
  @ViewChild('paginator')
  paginator: Paginator;
  searchCondition: any = {};
  showSearch: boolean = true;

  constructor(
    public http$: HttpClient,
    public modalService: ModalService,
    public ref: DynamicDialogRef,
    public resourceService: ResourceService,
    public paramsService: ParamsService,
    public userService: UserService,
    public alertService: AlertService
  ) {
    // this.searchCondition.businessUnitGid = userService.getCountryId();
  }

  public ngOnInit() {
    console.debug(this.paramsService.carrierData);
    this.columnDef = this.getColumnDef();
    this.searchCondition.servprovGid = this.paramsService.carrServprovGid;
    this.initData();
  }

  getColumnDef() {
    return [
      {
        elementId: this.resourceService.get('lbl_tms_truck_type_code'),
        propertyName: 'xid',
      },
      {
        elementId: this.resourceService.get('lbl_tms_truck_type_name'),
        propertyName: 'name',
      },
      {
        elementId: this.resourceService.get('lbl_tms_weight'),
        propertyName: 'effectiveWeight',
      },
      {
        elementId: this.resourceService.get('lbl_tms_volume'),
        propertyName: 'effectiveVolume',
      },
      {
        elementId: this.resourceService.get('lbl_tms_length'),
        propertyName: 'length',
      },
      {
        elementId: this.resourceService.get('lbl_tms_width'),
        propertyName: 'width',
      },
      {
        elementId: this.resourceService.get('lbl_tms_height'),
        propertyName: 'height',
      },
    ];
  }

  initData() {
    this.queryUserInfo().subscribe(data => {
      this.getBusinessUnitGid(
        data['userInfo']['userRestrictions'][0]['countryCode']
      );
    });
  }

  getBusinessUnitGid(value) {
    let params: HttpParams = new HttpParams();
    this.http$
      .get(tmsConfig.tms.getBusinessUnitGid + generateGid(value), {
        params: params,
      })
      .subscribe((res: any) => {
        this.searchCondition.businessUnitGid = res;
        this.search();
      });
  }

  public queryUserInfo() {
    return this.http$
      .post(
        '/htms/api/user/sce/getUserInfo',
        JSON.stringify({
          key: JSON.parse(localStorage.getItem('jwt_token')).token,
        })
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  search() {
    let params: HttpParams = new HttpParams();
    Object.keys(this.searchCondition).forEach(k => {
      if (this.searchCondition[k]) {
        params = params.set(k, this.searchCondition[k]);
      }
    });
    var permission = JSON.parse(localStorage.getItem('tms_button_permission'));
    var tenantId =
      permission['userRestrictions'][0]?.restrictions.filter(
        item => item.restrictionTypeName == 'Tenant ID'
      )[0]?.restrictionValue || 0;
    params = params
      .set('page', this.pageInfo.number || 0)
      .set('size', this.pageInfo.size || 10)
      .set('tenantID', tenantId);

    this.http$
      .get(tmsConfig.tms.equipmentGroupQueryUri, { params: params })
      .toPromise()
      .then((res: any) => {
        this.pageInfo = res;
      });
    this.selectedRow = null;
  }

  onConfirm() {
    this.paramsService.truckTypeDate = this.selectedRow;
    this.ref.close(this.selectedRow);
    // this.paramsService.searchService.performSearch();
    // this.confirmHandler=this.paramsService.confirmHandler;
    // if (this.confirmHandler) {
    //   this.confirmHandler(this.selectedRow).then((res: any) => {
    //     if (res.success) {
    //       this.alertService.clearAll().success(res.message);
    //       this.ref.close(TruckTypeDetailComponent)
    //       // this.modalService.modalReference.hide();
    //       this.paramsService.searchService.performSearch();
    //     } else {
    //       this.alertService.clearAll().error(res.message);
    //     }
    //   });
    // }
    // this.confirmHandler2=this.paramsService.confirmHandler2
    // if (this.confirmHandler2) {
    //   this.confirmHandler2({
    //     equipmentGroupGid: this.selectedRow.id,
    //     equipmentGroupName: this.selectedRow.name,
    //     equipmentGroupXid: this.selectedRow.xid,
    //   });
    //   this.ref.close(TruckTypeDetailComponent)
    // }

    // if (this.confirmHandler3) {
    //   this.confirmHandler3(this.selectedRow);
    // }
  }

  onCancel() {
    // if (this.cancelHandler) {
    //   this.cancelHandler();
    // } else {
    this.ref.close(TruckTypeDetailComponent);

    //   // this.modalService.modalReference.hide();
    // }
  }

  onPageChange($event: any) {
    this.pageInfo.number = this.paginator.getPage();
    this.pageInfo.size = this.paginator.rows;
    this.search();
  }

  onRowClick($event: any) {
    this.selectedRow = this.selectedRow === $event.data ? null : $event.data;
  }

  updateFilter() {
    setTimeout(() => {
      this.search();
    }, 200);
  }
}
