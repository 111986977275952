import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DATE_FORMATS = {
  YMD_HMS: 'YYYY-MM-DD HH:mm:ss',
  YMD_HMS_NEW: 'DD/MM/YYYY HH:mm:ss',
  YMD: 'YYYY-MM-DD',
};

export const DateUtil = {
  formatDate(value) {
    let v: any = new Date(value);
    if (value && v) {
      v = moment(value).format(DATE_FORMATS.YMD_HMS);
      if (v.length > DATE_FORMATS.YMD_HMS.length) {
        v = v.substring(0, DATE_FORMATS.YMD_HMS.length).replace('T', ' ');
      }
      return v;
    }
    return value;
  },
  formatDateYMD(value) {
    let v: any = new Date(value);
    if (value && v) {
      v = moment(value).format(DATE_FORMATS.YMD);
      if (v.length > DATE_FORMATS.YMD.length) {
        v = v.substring(0, DATE_FORMATS.YMD.length).replace('T', ' ');
      }
      return v;
    }
    return value;
  },

  formatDateNew(value) {
    let v: any = new Date(value);
    if (value && v) {
      v = moment(value).format(DATE_FORMATS.YMD_HMS_NEW);
      if (v.length > DATE_FORMATS.YMD_HMS_NEW.length) {
        v = v.substring(0, DATE_FORMATS.YMD_HMS_NEW.length).replace('T', ' ');
      }
      return v;
    }
    return value;
  },
};
