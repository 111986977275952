import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResMapService {
  mapInformationQuery = new Subject<any>();
  mapInfUpDate = new Subject<any>();
  newPoint = new Subject<any>();
  dropDateStatue = new Subject<any>();
  previewDate = new Subject<any>();
  manageNew = new Subject<any>();
  resetBtn = new Subject<any>();
  replenishDloding = new Subject<any>();
  serverConfigurationDloding = new Subject<any>();
  orderHistory = new Subject<any>(); // all order 历史修改记录
  orderEventUpload = new Subject<any>();
  ManageWorkflowAdd = new Subject<any>(); //Manage Workflow Result 上传
  createPDF = new Subject<any>(); // 生成 PDF shipment
  // currentMessage = this.mapInformationQuery.asObservable();
  constructor() {}

  setCallingMap(message: string) {
    this.mapInformationQuery.next(message);
  }
  setPreview(date) {
    this.previewDate.next(date);
  }
  getPreview(): Observable<any> {
    return this.previewDate.asObservable();
  }
}
