import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { UserPreferenceService } from '../layout/home/services/user-preference.service';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from 'sce-core';
import { ResourceService, UserService } from 'sce-core';
import { AppStorageService } from './security/app-storage.service';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation, Location } from '@angular/common';
import { ModalService } from '../shared/services/modal.service';
import { FileImportService } from '../shared/services/file-import.service';

@Injectable()
export class CoreServiceRegistry {
  constructor(
    public userService: UserService,
    public userPreferencesService: UserPreferenceService,
    public http$: HttpClient,
    public resourceService: ResourceService,
    public navigationService: NavigationService,
    public appStorageService: AppStorageService,
    public modalService: ModalService,
    public activatedRoute: ActivatedRoute,
    public platformLocation: PlatformLocation,
    public location: Location,
    public componentFactoryResolver: ComponentFactoryResolver,
    public fileImportService: FileImportService
  ) {
    // console.log('Core regisrtry', this.navigationService , this.modalService);
    // console.log('Core regisrtry file', this.fileImportService);
  }
}
