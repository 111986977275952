import { Injectable } from '@angular/core';
import { LocalPagedSearchService } from '../search/localpaged-search.service';

@Injectable()
export class FileImportService {
  public activeImportId: string;

  public importedRecords: Array<Array<string>>;

  public selectedFile: any;
  public selectedFiles: [File];

  /**
   * Transforms the Excel data to the client format
   * @param importId - passed to import component
   * @param searchService  - local paged
   * @param primaryIdProperty  - primart id from column
   * @param primaryIdValue  - primart id value
   */
  public transformDataFromImportFile(
    importId: string,
    searchService: LocalPagedSearchService,
    primaryIdProperty,
    primaryIdValue,
    columnMap
  ): string {
    if (importId !== this.activeImportId) {
      return 'invalid import id';
    }
    try {
      this.importedRecords[0].forEach((columnheader, index) => {
        if (columnMap[columnheader]) {
          this.importedRecords[0][index] = columnMap[columnheader];
        } else {
          const errMsg = 'invalid column headers';
          throw errMsg;
        }
      });
    } catch (errMsg) {
      return 'invalid column headers';
    }
    const pData = searchService.fullData;
    const primaryIdindex = this.importedRecords[0].indexOf(primaryIdProperty);
    if (!primaryIdValue) {
      primaryIdValue = this.importedRecords[1][primaryIdindex];
    }
    for (let i = 1; i < this.importedRecords.length; i++) {
      if (this.importedRecords[i].length > 0) {
        // Adding code for DataTable View Structure Transformation
        const rowDefinition = this.importedRecords[i];
        if (rowDefinition[primaryIdindex] === primaryIdValue) {
          const rowObject: any = new Object();
          rowObject.rIdx = i - 1;
          // Flag to select/deselect the respective Checkbox (default : unchecked)
          rowObject.isSelected = false;
          // Iterating and inserting the Excel data cells to each Row Object in RowData Array
          rowObject.cells = {};
          for (let j = 0; j < rowDefinition.length; j++) {
            const cellInfo: any = new Object();
            cellInfo.cVal = rowDefinition[j];
            cellInfo.cValOrig = rowDefinition[j];
            cellInfo.cValPrev = rowDefinition[j];
            cellInfo.cName = this.importedRecords[0][j];
            rowObject.cells[this.importedRecords[0][j]] = cellInfo;
          }
          rowObject.isEdited = true;
          pData.push(rowObject);
        } else {
          return 'Invalid primary id value';
        }
      }
    }
    return 'success';
  }
  //  all the line items won't have idedited flag as true
  public transformDataFromImportFileStoreToLoc(
    importId: string,
    searchService: LocalPagedSearchService,
    primaryIdProperty,
    primaryIdValue,
    columnMap
  ): string {
    if (importId !== this.activeImportId) {
      return 'invalid import id';
    }
    try {
      this.importedRecords[0].forEach((columnheader, index) => {
        if (columnMap[columnheader]) {
          this.importedRecords[0][index] = columnMap[columnheader];
        } else {
          const errMsg = 'invalid column headers';
          throw errMsg;
        }
      });
    } catch (errMsg) {
      return 'invalid column headers';
    }
    const pData = searchService.fullData;
    const primaryIdindex = this.importedRecords[0].indexOf(primaryIdProperty);
    if (!primaryIdValue) {
      primaryIdValue = this.importedRecords[1][primaryIdindex];
    }
    for (let i = 1; i < this.importedRecords.length; i++) {
      if (this.importedRecords[i].length > 0) {
        // Adding code for DataTable View Structure Transformation
        const rowDefinition = this.importedRecords[i];
        if (rowDefinition[primaryIdindex] === primaryIdValue) {
          const rowObject: any = new Object();
          rowObject.rIdx = i - 1;
          // Flag to select/deselect the respective Checkbox (default : unchecked)
          rowObject.isSelected = false;
          // Iterating and inserting the Excel data cells to each Row Object in RowData Array
          rowObject.cells = {};
          for (let j = 0; j < rowDefinition.length; j++) {
            const cellInfo: any = new Object();
            cellInfo.cVal = rowDefinition[j];
            cellInfo.cValOrig = rowDefinition[j];
            cellInfo.cValPrev = rowDefinition[j];
            cellInfo.cName = this.importedRecords[0][j];
            rowObject.cells[this.importedRecords[0][j]] = cellInfo;
          }
          pData.push(rowObject);
        } else {
          return 'Invalid primary id value';
        }
      }
    }
    return 'success';
  }
}
