import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'sce-core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SearchableEntityServiceFactory } from '../../../../shared/services/searchable-entity/searchable-entity-service-factory';
import { FleetMonitoringActionService } from '../../../../service/fleet-monitoring/fleet-monitoring-action.service';
import { FleetMonitoringBackendService } from '../../../../service/fleet-monitoring/fleet-monitoring-backend.service';
import { FleetMonitoringButtonService } from '../../../../service/fleet-monitoring/fleet-monitoring-button.service';
import { FleetMonitoringDataEditorService } from '../../../../service/fleet-monitoring/fleet-monitoring-data-editor.service';
import { FleetMonitoringDataService } from '../../../../service/fleet-monitoring/fleet-monitoring-data.service';
import { FleetMonitoringSearchService } from '../../../../service/fleet-monitoring/fleet-monitoring-search.service';
import { SearchService } from '../../../../shared/search/search.service';
import { AlertService } from 'sce-core';
import { DefaultMapService } from '../mapServes/services/fleet-map.service';
import { ResourceService } from 'sce-core';
import { tmsConfig } from 'src/app/core/tms-config';
import { transToLocationTime } from 'src/assets/utils/transToLocationTime';
import { generateGid } from '../../../../shared/services/business-unit-gid.util';
import { Router } from '@angular/router';

@Component({
  selector: 'lfwms-fleet-monitoring-map',
  templateUrl: './fleet-monitoring-map.component.html',
  styleUrls: ['./fleet-monitoring-map.component.css'],
  providers: [DefaultMapService],
})
export class FleetMonitoringMapComponent implements OnInit {
  public serviceFactory: SearchableEntityServiceFactory;
  public params: any = {};
  public detailModal: any;
  public isVehiclesSwitchChecked: boolean = true;
  public selectedTruckNumber: String = '';
  public showHeartbeatPoint: boolean = false;
  public queryDetailParams: any = {};
  calculation: any;
  public contentPartFlag: boolean = false;
  public currentMapData: any = {};
  public showSeetings: boolean = false;
  private refreshInterval: any;

  constructor(
    public navService: NavigationService,
    public dataService: FleetMonitoringDataService,
    public http$: HttpClient,
    public alertService: AlertService,
    public actionService: FleetMonitoringActionService,
    public buttonService: FleetMonitoringButtonService,
    public searchService: SearchService,
    public backendService: FleetMonitoringBackendService,
    public dataEditor: FleetMonitoringDataEditorService,
    public FleetMonitoringSearchService: FleetMonitoringSearchService,
    public resourceService: ResourceService,
    public mapService: DefaultMapService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public route: ActivatedRoute
  ) {
    //   this.activatedRoute.queryParams.subscribe(params => {
    //     // this.params = JSON.parse(params.data)
    // });
    this.serviceFactory = new SearchableEntityServiceFactory(
      dataService,
      actionService,
      backendService,
      dataEditor,
      buttonService
    );
  }

  ngOnInit(): void {
    window.addEventListener('keydown', ($event: any) => {
      console.log($event);
      if ($event.key == '=') {
        let mapCard = document.getElementById('mapCard');
        this.openFullscreen(mapCard);
      }
    });
    this.getRefreshMapData([], 1, 10).subscribe(newdata => {
      this.params = newdata;
      document.getElementById('fleetMap').innerHTML = '';
      this.mapService.initMap(
        'fleetMap',
        newdata.equipmentAll,
        this.currentMapData
      );
    });
    this.mapService.initMap(
      'fleetMap',
      this.params.equipmentAll,
      this.currentMapData
    );
    this.refreshMapData();
    this.refreshInterval = setInterval(() => {
      this.currentMapData = this.mapService.getCurrentCenter();
      console.log(
        this.mapService.getCurrentCenter(),
        'getCurrentCentergetCurrentCentergetCurrentCentergetCurrentCenter'
      );
      this.refreshMapData();
    }, 120000);
  }

  ngAfterContentInit() {
    let mapCard = document.getElementById('mapCard');
    this.openFullscreen(mapCard);
  }

  public openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  public exitFullscreen(elem) {
    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.msExitFullscreen) {
      // IE/Edge
      elem.msExitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      // Firefox
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitExitFullscreen();
    }
  }

  public refreshMapData() {
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = generateGid(
      userinfo['userRestrictions'][0]['countryCode']
    );
    if (!this.queryDetailParams) {
      this.getRefreshMapData([], 1, 10).subscribe(newdata => {
        document.getElementById('fleetMap').innerHTML = '';
        this.mapService.initMap(
          'fleetMap',
          newdata.equipmentAll,
          this.currentMapData
        );
      });
    } else {
      this.queryDetailParams.country = countryKey;
      const params = this.queryDetailParams;
      this.queryDetail(params).subscribe(res => {
        console.log(res, '这里是查询的详情接口');
        let newpolylineList = [];
        if (res.arrivalStopRoutes?.routes) {
          res.arrivalStopRoutes.routes[0]?.sections.forEach(ele => {
            newpolylineList.push(ele['polyline']);
          });
        }
        const utcfFOrmattedDate = this.UTCTime(res.plannedArrival);
        const formattedDate = this.formatDatetoString(utcfFOrmattedDate);
        res.formattedDate = formattedDate;
        const formatedETA = this.formatETA(formattedDate);
        res.formatedETA = formatedETA;
        this.detailModal = res;
        this.contentPartFlag = true;
        // this.mapService.removeMarkersFromMap()
        this.currentMapData = {
          center: {
            alt: 0,
            lat: res.lat,
            lng: res.lng,
          },
          currentZoom: this.mapService.getCurrentCenter().currentZoom,
        };
        document.getElementById('fleetMap').innerHTML = '';
        this.mapService.initMap('fleetMap', [], this.currentMapData);
        this.mapService.addHeartbeatPoint(
          this.groupAndExtractFirst(res.locationHeartbeatList)
        );
        this.mapService.addNowPoint(
          res.lat,
          res.lng,
          res.shipmentStopList,
          res.course
        );
        let polylineList = [];
        this.getPolyline(res.allocatedShipmentGid).subscribe(res => {
          if (res?.routes) {
            res.routes[0]?.sections.forEach(ele => {
              polylineList.push(ele['polyline']);
            });
          }
          this.mapService.addNewRouteLine(newpolylineList);
          this.mapService.addRouteLine(polylineList);
        });
      });
    }
  }
  public getRefreshMapData(value: any, pageNum: any, pageSize: any) {
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    var permissionAddUrl = localStorage.getItem('permissionAddUrl');
    let headparams: any = [];
    const response$: any = this.http$
      .post(
        '/htms/v1/0/search-conditions/vehicles-monitoring/' +
          countryKey +
          '?page=' +
          pageNum +
          '&size=' +
          pageSize +
          permissionAddUrl,
        JSON.stringify(headparams)
      )
      .pipe(
        map((response: any) => {
          console.log(response);
          return response;
        }),
        catchError((error: any) => {
          this.alertService.error(error.message);
          return this.resourceService.get(error.message);
        })
      );
    return response$;
  }
  public handleVehiclesSwitchChange(event: { checked: boolean }) {
    console.log('Switch changed to:', event.checked);
    if (event.checked) {
      document.getElementById('fleetMap').innerHTML = '';
      this.mapService.initMap(
        'fleetMap',
        this.params.equipmentAll,
        this.currentMapData
      );
    } else {
      document.getElementById('fleetMap').innerHTML = '';
      this.mapService.initMap('fleetMap', [], {});
    }
  }
  public onTruckSelected(event) {
    // event.value 将包含被选中项的 truckXid
    console.log('选中的车辆ID:', event.value);
    if (!event.value) {
      this.contentPartFlag = false;
      document.getElementById('fleetMap').innerHTML = '';
      this.mapService.initMap('fleetMap', this.params.equipmentAll, {});
      this.queryDetailParams = {
        country: '',
        truckGid: '',
        truckXid: '',
        allocatedShipmentGid: '',
        allocatedShipmentXid: '',
        lastReportingDateStart: '',
      };
      return;
    }
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    let lastReportingDateStart = this.timeSubtraction(
      event.value.lastReportingDate
    );
    const params = {
      truckGid: event.value.truckGid,
      truckXid: event.value.truckXid,
      allocatedShipmentGid: event.value.allocatedShipmentGid,
      allocatedShipmentXid: event.value.allocatedShipmentXid,
      country: countryKey,
      lastReportingDateStart,
    };
    (this.queryDetailParams = params),
      this.queryDetail(params).subscribe(res => {
        this.calculation = true;
        console.log(res, '这里是查询的详情接口');
        let newpolylineList = [];
        if (res.arrivalStopRoutes?.routes) {
          res.arrivalStopRoutes.routes[0]?.sections.forEach(ele => {
            newpolylineList.push(ele['polyline']);
          });
        }
        const utcfFOrmattedDate = this.UTCTime(res.plannedArrival);
        const formattedDate = this.formatDatetoString(utcfFOrmattedDate);
        res.formattedDate = formattedDate;
        const formatedETA = this.formatETA(formattedDate);
        res.formatedETA = formatedETA;
        this.detailModal = res;
        if (this.calculation) {
          this.detailModal.ETATimes = this.getTime(
            this.detailModal?.arrivalStopTravelDuration
          );
        }
        this.contentPartFlag = true;
        // this.mapService.removeMarkersFromMap()
        document.getElementById('fleetMap').innerHTML = '';
        this.mapService.initMap('fleetMap', [], {});
        this.mapService.addNowPoint(
          res.lat,
          res.lng,
          res.shipmentStopList,
          res.course
        );
        this.mapService.addHeartbeatPoint(
          this.groupAndExtractFirst(res.locationHeartbeatList)
        );
        let polylineList = [];
        this.getPolyline(res.allocatedShipmentGid).subscribe(res => {
          if (res?.routes) {
            res.routes[0]?.sections.forEach(ele => {
              polylineList.push(ele['polyline']);
            });
          }
          this.mapService.addNewRouteLine(newpolylineList);
          this.mapService.addRouteLine(polylineList);
        });
      });
  }
  public groupAndExtractFirst(arr) {
    console.log(arr);
    let result = [];
    let currentGroup = [];

    arr.forEach(item => {
      currentGroup.push(item);

      if (currentGroup.length === 5) {
        result.push(currentGroup[0]);
        currentGroup = [];
      }
    });

    if (currentGroup.length > 0) {
      result.push(currentGroup[0]);
    }
    return result;
  }
  public addHeartbeat() {
    if (this.showHeartbeatPoint && this.detailModal) {
      let newpolylineList = [];
      if (this.detailModal.arrivalStopRoutes?.routes) {
        this.detailModal.arrivalStopRoutes.routes[0]?.sections.forEach(ele => {
          newpolylineList.push(ele['polyline']);
        });
      }
      document.getElementById('fleetMap').innerHTML = '';
      this.mapService.initMap('fleetMap', [], {});
      this.mapService.addNowPoint(
        this.detailModal.lat,
        this.detailModal.lng,
        this.detailModal.shipmentStopList,
        this.detailModal.course
      );
      // this.mapService.addHeartbeatPoint(this.groupAndExtractFirst(res.locationHeartbeatList))
      let polylineList = [];
      this.getPolyline(this.detailModal.allocatedShipmentGid).subscribe(res => {
        if (res?.routes) {
          res.routes[0]?.sections.forEach(ele => {
            polylineList.push(ele['polyline']);
          });
        }
        this.mapService.addNewRouteLine(newpolylineList);
        this.mapService.addRouteLine(polylineList);
      });
    }
    if (this.detailModal && this.detailModal.locationHeartbeatList) {
      this.showHeartbeatPoint = !this.showHeartbeatPoint;
      this.mapService.addHeartbeatPoint(
        this.groupAndExtractFirst(this.detailModal.locationHeartbeatList)
      );
    }
  }
  public queryDetail(value: any) {
    console.log(value, '这里是查询参数');
    const response$: any = this.http$
      .post('/htms/v1/0/vehicles/gps/heartbeat', JSON.stringify(value))
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          this.alertService.error(error.message);
          return this.resourceService.get(error.message);
        })
      );
    return response$;
  }
  goToList() {
    this.router.navigate(['/home/control-tower/fleet-monitoring/search']);
  }
  handleSeetings() {
    this.showSeetings = !this.showSeetings;
  }
  // 获取画线
  public getPolyline(id) {
    let parmes = {
      id,
    };
    var url = tmsConfig.tms.shipmentGetRoutes;
    const response$: any = this.http$
      .post(url + '?page=0&size=10', JSON.stringify(parmes))
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return this.resourceService.get(error.message);
        })
      );
    return response$;
  }
  public formatDatetoString(dateString) {
    const date = new Date(dateString);

    // 确保日期是有效的
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const hours = date.getHours() % 12 || 12; // 转换为12小时制，并处理0小时的情况
    const ampm = hours < 12 ? 'AM' : 'PM';

    return `${monthNames[date.getMonth()]} ${date.getDate()} at ${hours}:${('0' + date.getMinutes()).slice(-2)} ${ampm}`;
  }
  public formatETA(date) {
    const timeRegex = /at\s+(\d{1,2}:\d{1,2}\s[AP]M)/;
    const match = date.match(timeRegex);

    // 如果找到了匹配项，则返回时间部分
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  UTCTime(date) {
    if (date) {
      var localDate = new Date(date); // 请根据需要替换为你的本地时间
      var utcDate = localDate.toLocaleString('en-US', {
        timeZone: 'UTC',
        hour12: false,
      });
      // let dates =  new Date(utcDate)
      // let formattedTime = dates.toISOString().replace("T", " ").replace("Z", "");
      console.log('UTC date: ' + utcDate);
      // console.log("UTC date: " + formattedTime);
      let dateParts = utcDate.split(', ')[0].split('/');
      let timePart = utcDate.split(', ')[1];
      if (timePart.startsWith('24')) {
        timePart = '00' + timePart.slice(2);
      }
      let year = dateParts[2];
      let month = dateParts[0].padStart(2, '0');
      let day = dateParts[1].padStart(2, '0');

      let formattedDate = `${year}-${month}-${day} ${timePart}`;
      console.log(formattedDate);
      return formattedDate;
    } else {
      return;
    }
  }
  ngOnDestroy(): void {
    // 在组件销毁时清除定时器，避免内存泄漏
    clearInterval(this.refreshInterval);
  }
  getTime(min) {
    if (this.calculation && min) {
      const currentDate = new Date();
      console.log(currentDate);
      currentDate.setMinutes(currentDate.getMinutes() + Number(min));
      console.log(currentDate);
      const year = currentDate.getFullYear(); // 获取年份
      const month = currentDate.getMonth() + 1; // 获取月份（从0开始，所以需要+1）
      const day = currentDate.getDate(); // 获取日期
      const hours = currentDate.getHours(); // 获取小时
      const minutes = currentDate.getMinutes(); // 获取分钟
      const seconds = currentDate.getSeconds(); // 获取秒
      // 拼接成想要的格式
      const formattedTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      console.log('预计时间：', formattedTime);
      this.calculation = false;
      return formattedTime;
    }
  }
  public transToLocationTimes(value) {
    return transToLocationTime(value);
  }
  timeSubtraction(date) {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() - 3);
    console.log(currentDate);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedTime;
  }
}
