import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentUrl = state.url;
    if (currentUrl === '/login') {
      const redirectMap: { [key: string]: string } = {
        cdt: 'https://cdt-wms.fulfillment.maersk.com/',
        stage: 'https://stage-wms.fulfillment.maersk.com/',
        americas: 'https://americas-wms.fulfillment.maersk.com/',
      };
      for (const key in redirectMap) {
        if (origin.includes(key)) {
          window.location.href = redirectMap[key];
          return false;
        }
      }
    }

    return true;
  }
}
