import { Injectable } from '@angular/core';
import { ConfirmDialogService, NavigationService } from 'sce-core';
import { ResourceService } from 'sce-core';
import { AlertService } from 'sce-core';
import { SearchableEntityButtonService } from './searchable-entity-button.service';
import { SearchableEntityBackendService } from './searchable-entity-backend.service';
import { SearchableEntityDataService } from './searchable-entity-data.service';
import { SearchableEntityDataEditorService } from './searchable-entity-data-editor.service';
import { SearchService } from '../../search/search.service';
import { ValidatorService } from '../validator.service';
import { SearchEvent } from '../../search/models/search-event';
import { REFRESH_SEARCH_EVENT } from '../../search/search-constants';
import { ModalService } from '../modal.service';
import { CoreServiceRegistry } from 'src/app/core/core-service-registry';
import { SharedServiceRegistry } from 'src/app/shared/shared-service-registry';

@Injectable()
export class SearchableEntityActionService {
  public errorMsg: string = '';
  public successMsg: string = '';
  public dialogMsg: string = '';
  public columnDef: any[];

  public dataService: SearchableEntityDataService;
  public buttonService: SearchableEntityButtonService;
  public backendService: SearchableEntityBackendService;
  public dataEditor: SearchableEntityDataEditorService;

  public navService: NavigationService;
  public confirmDialogService: ConfirmDialogService;
  public alertService: AlertService;
  public resourceService: ResourceService;
  public modalService: ModalService;
  public validatorService: ValidatorService;

  constructor(
    public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry
  ) {
    this.navService = coreServiceRegistry.navigationService;
    this.resourceService = coreServiceRegistry.resourceService;
    this.alertService = sharedServiceRegistry.alertService;
    this.confirmDialogService = sharedServiceRegistry.confirmDialogService;
    this.modalService = sharedServiceRegistry.sceModalService;
    this.validatorService = sharedServiceRegistry.validatorService;
  }

  public setDependentServices(
    dataService,
    buttonService,
    dataEditor,
    backendService
  ) {
    this.dataService = dataService;
    this.buttonService = buttonService;
    this.dataEditor = dataEditor;
    this.backendService = backendService;
  }

  // common function for export
  public exportData(
    searchService: SearchService,
    format: string,
    tableId?: string
  ) {
    if (!searchService || !format) {
      this.alertService.error('');
    }
    // Filter searchCriteria with * in fac/storer. Assuming condition[0] is the first mandatory condition
    const clausesWithStarFacilityStorer =
      searchService.searchCriteria.conditions[0].clauses.filter(
        clause =>
          (clause.column === 'storerKey' || clause.column === 'facility') &&
          clause.value === '*'
      );
    // check if any storer clause or facility clause with * is present in filtered data.
    if (clausesWithStarFacilityStorer.length > 0) {
      //     LFWM-1880  --  Export function restriction
      this.alertService.error(
        this.resourceService.get('msg_export_star_facstorer')
      );
      return;
    }
    // Alert user and continue export in background
    this.alertService.warn(this.resourceService.get('msg_export_bg_info'));
    searchService.exportData(format, tableId).subscribe(resp => {
      const blob = new Blob([this.s2ab(atob(resp.content))]);
      const file = new File([blob], resp.fileName, { type: 'text/csv' });
      this.sharedServiceRegistry.fileUtilityService.saveFileLocally(file);
    });
  }

  public s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  // Method used to copy value to all rows for locally paged screens
  public copyValueToAllRowsForLocallyPaged() {
    const noOfLineItems = this.dataService.searchService.fullData.length;
    let selectedColumnToCopy = '';
    let valueToCopy = '';
    if (this.dataService.searchService.getFocusedRow().cName) {
      selectedColumnToCopy =
        this.dataService.searchService.getFocusedRow().cName;
    }
    if (this.dataService.searchService.getFocusedRow().row) {
      valueToCopy =
        this.dataService.searchService.getFocusedRow().row.cells[
          selectedColumnToCopy
        ].cVal;
    }
    if (noOfLineItems === 0) {
      this.alertService
        .clearAll()
        .error(this.resourceService.get(this.errorMsg));
      return;
    }
    // code to copy the focused row value to all rows
    const allLineItems = this.dataService.searchService.fullData;
    allLineItems.forEach((lineItemRows: any) => {
      const lineItemCells = lineItemRows.cells;
      lineItemCells[selectedColumnToCopy].cVal = valueToCopy;
    });
    this.dataService.searchService.publishEvent(
      new SearchEvent(REFRESH_SEARCH_EVENT)
    );
  }
}
