/* The file contents for the current environment will overwrite these during build.
   The build system defaults to the dev environment which uses `environment.ts`, but if you do
   `ng build --env=prod` then `environment.prod.ts` will be used instead.
   The list of which env maps to which file can be found in `.angular-cli.json`. */

import { sceconfig } from 'sce-core';

export const environment = {
  production: false,
  logger: 'console',
  baseUrlUI: sceconfig.wms.baseUrlUI,

  // wmsApiBaseUrl: 'https://cdt-wms.fulfillment.maersk.com/scecommon/', // cdt envrionment
  // notificationApiBaseUrl: 'https://cdt-tms.fulfillment.maersk.com/api', // cdt envrionment

  wmsApiBaseUrl: 'https://stage-wms.ocf.fulfillment.maersk.com/scecommon/', // wrb uat envrionment
  notificationApiBaseUrl: 'https://stage-tms.ocf.fulfillment.maersk.com/api' // wrb uat envrionment

  // wmsApiBaseUrl: 'https://cdt-wms.ocf.fulfillment.maersk.com/scecommon/',  // localhost envrionment
  // notificationApiBaseUrl: 'https://cdt-tms.ocf.fulfillment.maersk.com/api' // localhost envrionment

  // wmsApiBaseUrl: 'https://sit-wms.ocf.fulfillment.maersk.com/scecommon/', // wrb sit envrionment
  // notificationApiBaseUrl: 'https://sit-tms.ocf.fulfillment.maersk.com/api' // wrb sit envrionment

  // wmsApiBaseUrl: 'https://wms.ocf.fulfillment.maersk.com'/scecommon/', // wrb prod envrionment
  // notificationApiBaseUrl: 'https://tms.ocf.fulfillment.maersk.com'/api' // wrb prod envrionment
};
