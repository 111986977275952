import { AlertService, UserService } from 'sce-core';
import { Injectable } from '@angular/core';
import { SearchService } from './search.service';
import { HttpClient } from '@angular/common/http';
import { SearchParams } from './models/search-params.model';
import { ResourceService } from 'sce-core';
import { SearchStatus } from './models/search-status.model';
import { UserPreferenceService } from '../../layout/home/services/user-preference.service';
import { PagedData } from './models/paged-data.model';
import { TableRow } from './models/table-row.model';
import { Observable, ReplaySubject } from 'rxjs';
import { PageDefinition } from './models/page-definition.model';
import { SearchEvent } from './models/search-event';
import { NEW_ROW_EVENT } from './search-constants';
import { DatePickerConfigService } from '../components/datepicker/datepicker-config.service';

@Injectable()
export class LocalPagedSearchService extends SearchService {
  constructor(
    public http$: HttpClient,
    public searchParams: SearchParams,
    public searchStatus: SearchStatus,
    public resourceService: ResourceService,
    public userPreferenceService: UserPreferenceService,
    public alertService: AlertService,
    public dateConfigService: DatePickerConfigService,
    public userService: UserService
  ) {
    super(
      http$,
      searchParams,
      searchStatus,
      resourceService,
      userPreferenceService,
      alertService,
      dateConfigService,
      userService
    );
    this.pagedData = new PagedData<TableRow>();
  }

  /**
   *this method is used to send the results according to pagination
   *
   */
  public fetchSearchResults(): Observable<any> {
    if (this.searchStatus.hasResults) {
      return this.fetchResultsFromLocalModel();
    } else {
      return super.fetchSearchResults();
    }
  }

  public fetchResultsFromLocalModel() {
    this.pagedData.pageRows = [];
    const tableId = this.searchParams.tableId;
    const pageInfoReq = {
      tableId: tableId,
      pageNo: this.pagedData.pageDefinition.pageNumber,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
      fullFetch: this.searchParams.isFullFetch,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.formatSearchCriteria(
        this.searchCriteria,
        this.searchParams.additionalParamMap,
        this.filterCondition
      ),
    };
    // contains the entire data from imported file
    // const allData = this.fullData;
    let filteredData = [];

    // if the search criteria is not empty, filter the data based on that
    if (
      payload.searchCriteria['conditions'] &&
      payload.searchCriteria['conditions'].length > 0
    ) {
      // filtering based on search criteria
      filteredData = this.dataManager.filterDataForSearchCriteria(
        payload.searchCriteria,
        this.fullData
      );
    } else {
      filteredData = this.fullData;
    }

    // if the sort column is set, then sort the data
    if (this.pagedData.pageDefinition.sortColumn) {
      const sortColumn = this.getMetadataForProperty(
        this.pagedData.pageDefinition.sortColumn
      );
      this.dataManager.sortColumn(
        filteredData,
        sortColumn,
        this.pagedData.pageDefinition.sortOrder
      );
      // this.pagedData.pageDefinition.sortOrder = (this.pagedData.pageDefinition.sortOrder === 0) ? 1 : 0;
    }

    let pageData = [];
    // if the filtered data is larger than the page size, extract data for the selected page
    if (filteredData.length > this.pagedData.pageDefinition.pageSize) {
      pageData = this.spliceBasedOnPagination(filteredData);
    } else {
      pageData = filteredData;
    }

    const searchRequestSubject = new ReplaySubject();
    // const obs: Observable<any> = new Observable();
    setTimeout(() => {
      this.setDataForPage(pageData, filteredData.length);
      searchRequestSubject.next(pageData);
    }, 0);
    return searchRequestSubject.asObservable();
  }

  /**
   * Transforms the Excel data to the client format
   * @param dataToPopup - Excel data
   * @param toataldata  - Excel data
   */
  public setDataForPage(pageData, recordCount) {
    const pDef: PageDefinition = this.pagedData.pageDefinition;
    const pData: Array<TableRow> = this.pagedData.pageRows;
    pDef.pageNumber = this.pagedData.pageDefinition.pageNumber;
    pDef.sortColumn = this.pagedData.pageDefinition.sortColumn;
    pDef.sortOrder = this.pagedData.pageDefinition.sortOrder;
    pDef.recordCount = recordCount;
    pDef.pageSize = this.pagedData.pageDefinition.pageSize;
    pData.splice(0);
    pageData.forEach(data => {
      pData.push(data);
    });
  }

  /**
   * Transforms the search response to the client format
   *
   * @param response - Server response
   */
  public transformSearchResponse(response: any) {
    const pDef: PageDefinition = this.pagedData.pageDefinition;
    const pData: Array<TableRow> = this.pagedData.pageRows;
    pDef.pageNumber = 1;
    pDef.sortColumn = response['sortColumn'];
    pDef.sortOrder = +response['sortOrder'];
    pDef.recordCount = +response['totalRecords'];
    pDef.pageSize = +response['pageSize'];
    this.searchStatus.hasResults = true;
    if (!response['pageData'] || !response['pageData'].rows) {
      return;
    }
    if (!pDef.recordCount) {
      pDef.recordCount = response['pageData'].rows.length;
    }
    const pageSize =
      pDef.recordCount > pDef.pageSize ? pDef.pageSize : pDef.recordCount;
    for (let i = 0; i < pageSize; i++) {
      // Adding code for DataTable View Structure Transformation
      const rowDefinition = response['pageData'].rows[i];
      const rowObject: any = new Object();
      rowObject.rIdx = rowDefinition.rIdx;
      // Flag to select/deselect the respective Checkbox (default : unchecked)
      rowObject.isSelected = false;
      // Iterating and inserting the server response cells to each Row Object in RowData Array
      rowObject.cells = {};
      rowDefinition.cells.forEach((cell: any) => {
        const cellInfo: any = new Object();
        cellInfo.cVal = cell.cVal;
        cellInfo.cValOrig = cell.cVal;
        cellInfo.cValPrev = cell.cVal;
        cellInfo.cName = cell.cName;
        rowObject.cells[cell.cName] = cellInfo;
      });
      pData.push(rowObject);
    }
    /*this.data is used to transfer the data of search service to childsearchsevices, eg.PalletImageSearchSrvice */
    this.fullData = [].concat(pData);

    // adding the remaining data to the model after a delay, so that all the other page events can get finished,
    // so that the table loads faster
    if (pageSize < pDef.recordCount) {
      setTimeout(() => {
        this.addAdditionalPageDataToModel(response);
      }, 2000);
    }
  }

  public addAdditionalPageDataToModel(response) {
    const rowCount = response['pageData'].rows.length;
    for (let i = this.pagedData.pageDefinition.pageSize; i < rowCount; i++) {
      // Adding code for DataTable View Structure Transformation
      const rowDefinition = response['pageData'].rows[i];
      const rowObject: any = new Object();
      rowObject.rIdx = rowDefinition.rIdx;
      // Flag to select/deselect the respective Checkbox (default : unchecked)
      rowObject.isSelected = false;
      // Iterating and inserting the server response cells to each Row Object in RowData Array
      rowObject.cells = {};
      rowDefinition.cells.forEach((cells: any) => {
        const cellInfo: any = new Object();
        cellInfo.cVal = cells.cVal;
        cellInfo.cValOrig = cells.cVal;
        cellInfo.cValPrev = cells.cVal;
        cellInfo.cName = cells.cName;
        rowObject.cells[cells.cName] = cellInfo;
      });
      this.fullData.push(rowObject);
    }
  }

  /**
   *this method contains logic to slice record based on pagination
   *
   */
  public spliceBasedOnPagination(allData) {
    const arrayAfterSplicedOnPagination = [];
    if (allData.length > 0) {
      const pageNumber = this.pagedData.pageDefinition.pageNumber;
      const pageSize = this.pagedData.pageDefinition.pageSize;
      const startIndex = pageNumber * pageSize - pageSize;
      const endIndex = pageNumber * pageSize;
      const splicedData = allData.slice(startIndex, endIndex);
      splicedData.map(requiredLineItem => {
        arrayAfterSplicedOnPagination.push(requiredLineItem);
      });
      return arrayAfterSplicedOnPagination;
    } else {
      return arrayAfterSplicedOnPagination;
    }
  }

  /**
   * Adds a new row locally (client side only) to the current data table. The new row will be put to the first position
   */
  public addNewRowToModel() {
    const rowData = super.createNewRow();
    const insertPosition =
      (this.pagedData.pageDefinition.pageNumber - 1) *
      this.pagedData.pageDefinition.pageSize;
    this.fullData.splice(insertPosition, 0, rowData);
    const newRowEvent: SearchEvent = new SearchEvent(NEW_ROW_EVENT);
    newRowEvent.selectedRow = rowData;
    this.publishEvent(newRowEvent);
  }

  /**
   * Deletes the selected row locally (client side only)
   *
   * @param idProperty - Id property
   * @param rowsToDelete - Value
   */
  public deleteRowsFromModel(indextoDelete: any): any | undefined {
    if (this.metadata === null || this.metadata['columnList'] === null) {
      return 'meta data not loaded';
    }
    if (
      this.pagedData.pageRows === undefined ||
      this.pagedData.pageRows === null
    ) {
      return 'page data not loaded';
    }
    if (indextoDelete !== null && indextoDelete !== undefined) {
      const startIndex =
        (this.pagedData.pageDefinition.pageNumber - 1) *
          this.pagedData.pageDefinition.pageSize +
        indextoDelete;
      this.fullData.splice(startIndex, 1);
      this.pagedData.pageRows.splice(indextoDelete, 1);
    }
  }
}
